import React, { useState, useRef, useEffect } from 'react';
import cx from 'clsx';

type ThumbnailsProps = {
    images: string[];
    currentIndex: number;
    onClickImage: (index: number) => void;
};

const Thumbnails: React.FC<ThumbnailsProps> = ({ images, currentIndex, onClickImage }) => {
    const [isScrolledTop, setIsScrolledTop] = useState(false);
    const [isScrolledBottom, setIsScrolledBottom] = useState(false);
    const thumbnailsRef = useRef<HTMLDivElement>(null);

    const scrollThumbnails = () => {
        if (thumbnailsRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = thumbnailsRef.current;
            const scrollPercent = scrollTop / (scrollHeight - clientHeight); // from 0 to 1
            setIsScrolledTop(scrollPercent > 0);
            setIsScrolledBottom(scrollPercent < 1);
        }
    };

    useEffect(() => {
        if (thumbnailsRef.current) {
            const element = thumbnailsRef.current;
            element.addEventListener('scroll', scrollThumbnails);
            return () => {
                element.removeEventListener('scroll', scrollThumbnails);
            };
        }
    }, []);

    useEffect(() => {
        if (thumbnailsRef.current) {
            const thumbnailHeight = thumbnailsRef.current.scrollHeight / images.length;

            thumbnailsRef.current.scrollTo({
                top: thumbnailHeight * currentIndex,
                behavior: 'smooth',
            });
        }
    }, [currentIndex]);

    return (
        <div className="relative hidden h-full overflow-y-hidden xl:block">
            <div
                className={cx('absolute left-0 right-0 top-0 h-[50px] w-full bg-gradient-to-b text-transparent', {
                    'from-white to-transparent opacity-85': isScrolledTop,
                    'opacity-0': !isScrolledTop,
                })}
            >
                gradient
            </div>
            <div
                ref={thumbnailsRef}
                className="scrollbar-hide flex h-full flex-col items-center justify-start gap-2 overflow-hidden overflow-y-scroll"
            >
                {images.map((image, index) => (
                    <div
                        key={index}
                        className="flex size-44 cursor-pointer items-center bg-gray-200"
                        onClick={() => onClickImage(index)}
                    >
                        <img
                            className="mx-auto h-full max-h-[28.5rem] object-cover"
                            src={image}
                            alt={`Thumbnail ${index + 1}`}
                            loading="lazy"
                        />
                    </div>
                ))}
            </div>
            <div
                className={cx('sticky bottom-0 left-0 right-0 h-[50px] w-full bg-gradient-to-t text-transparent', {
                    'from-white to-transparent opacity-85': isScrolledBottom,
                    'opacity-0': !isScrolledBottom,
                })}
            >
                gradient
            </div>
        </div>
    );
};

export default Thumbnails;
