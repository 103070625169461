import React from 'react';
import { useTranslation } from '@/lib/i18n';
import { Alert, Text } from '@/components/ui';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

type Props = {
    isSuccess: boolean;
    isError: boolean;
};

const AlertZone: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [status, setStatus] = React.useState<'success' | 'error' | null>(null);
    const nodeRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (props.isSuccess) {
            setStatus('success');
        }
    }, [props.isSuccess]);

    React.useEffect(() => {
        if (props.isError) {
            setStatus('error');
        }
    }, [props.isError]);

    React.useEffect(() => {
        let timeoutId: number | undefined = undefined;
        timeoutId = window.setTimeout(() => {
            setStatus(null);
        }, 3000);
        return () => clearTimeout(timeoutId);
    }, [status]);

    return (
        <SwitchTransition>
            <CSSTransition key={status} timeout={300} classNames="fade" nodeRef={nodeRef}>
                <div ref={nodeRef}>
                    {status ? (
                        <Alert className="m-0 uppercase max-sm:w-full" color={status}>
                            {t(`profile.delivery_details_form.${status}`)}
                        </Alert>
                    ) : (
                        <div className="flex w-full items-center justify-start">
                            <Text.Body>{t('fields_required')}</Text.Body>
                        </div>
                    )}
                </div>
            </CSSTransition>
        </SwitchTransition>
    );
};

export default AlertZone;
