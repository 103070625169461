import React from 'react';
import { useTranslation } from '@/lib/i18n';
import { Text, DownloadFile } from '@/components/ui';

type DownloadDocumentsProps = {
    productId: string;
};
const DownloadDocuments: React.FC<DownloadDocumentsProps> = ({ productId }) => {
    const { t } = useTranslation();
    return (
        <div className="flex w-full flex-col justify-start gap-10">
            <Text.H2>{t('download_documents')}</Text.H2>
            <div className="grid gap-8 md:grid-cols-3">
                <DownloadFile href={`/file/oxino_${productId}.pdf`} title={`OXINO №${productId}`} />
            </div>
        </div>
    );
};

export default DownloadDocuments;
