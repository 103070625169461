import React from 'react';
import { Expanzi, Logo as ExpanziLogo } from '@/svg';

import Text from '../text/Text';

type LogoProps = {
    href?: string;
    long?: boolean;
};

const Logo: React.FC<LogoProps> = ({ long = true, ...props }) => {
    const Tag = props.href ? 'a' : 'div';
    return (
        <Tag className="flex items-center gap-3 text-white" {...props}>
            <div className="flex items-center gap-1">
                <ExpanziLogo className="h-5 w-5 min-[390px]:w-10 sm:h-7 sm:w-16" />
                <Expanzi className="mt-1 h-5 w-16 min-[390px]:w-28 sm:h-7 sm:w-32" />
            </div>
            {long ? (
                <Text.Subtitle className="max-xl:text-xl" as="span">
                    Shop
                </Text.Subtitle>
            ) : null}
        </Tag>
    );
};

export default Logo;
