'use client';

import React, { useEffect, useRef } from 'react';

import { signIn } from 'auth-astro/client';

import Loader from '@/components/ui/loader/Loader';
import { Dialog, DialogNoCloseContent, DialogTitle } from '@/components/ui';
import { resetCartDetails } from '@/lib/cart.ts';

interface Props {
    token?: string;
}

export default function AutoLogin(props: Props) {
    const { token } = props;

    const inProgressRef = useRef(false);

    async function autoLogin() {
        await signIn('credentials', {
            redirect: false,
            token,
        });
        resetCartDetails();
        window.location.href = '/';
        return;
    }

    useEffect(() => {
        if (inProgressRef.current) {
            return;
        }
        inProgressRef.current = true;
        autoLogin();
    }, []);

    return (
        <Dialog open>
            <DialogNoCloseContent>
                <div className="relative mt-4">
                    <Loader isVisible />
                    <DialogTitle>&nbsp;</DialogTitle>
                </div>
            </DialogNoCloseContent>
        </Dialog>
    );
}
