import cx from 'clsx';
import React from 'react';
import { Arrow } from '@/svg';

type ControlsProps = {
    images: string[];
    currentIndex: number;
    onClickNext: () => void;
    onClickPrevious: () => void;
    onClick: (index: number) => void;
};

const Controls: React.FC<ControlsProps> = ({ images, currentIndex, onClick, onClickNext, onClickPrevious }) => {
    return (
        <div className="bg-primary-600 absolute bottom-6 z-10 flex gap-4 rounded-full bg-opacity-5 px-2">
            <button type="button" className="text-primary-600 rounded-full p-2" onClick={onClickPrevious}>
                <Arrow className="rotate-180" />
            </button>
            <div className="mt-4 flex justify-center gap-2">
                {images.map((_, index) => (
                    <button
                        key={index}
                        className={cx('bg-primary-600 h-3 w-3 rounded-full', {
                            ['opacity-30']: currentIndex !== index,
                        })}
                        type="button"
                        onClick={() => onClick(index)}
                    />
                ))}
            </div>
            <button type="button" className="text-primary-600 rounded-full p-2" onClick={onClickNext}>
                <Arrow />
            </button>
        </div>
    );
};

export default Controls;
