import React from 'react';
import { Copy } from '@/svg';
import { Text } from '@/components/ui';
import { handleCopy } from '@/lib/handleCopy';
import type { Price } from '@/lib/resources/product';

type PaymentInfoItemProps = {
    title: string;
    value: string;
    price?: Price;
};

const PaymentInfoItem: React.FC<PaymentInfoItemProps> = ({ title, value }) => {
    return (
        <div className="flex flex-col gap-2 lg:flex-row">
            <Text.Body>{title}:</Text.Body>
            <div className="flex items-center gap-2">
                <Text.Body bold>{value}</Text.Body>
                <button className="text-gray-500" onClick={() => handleCopy(value)}>
                    <Copy />
                </button>
            </div>
        </div>
    );
};

export default PaymentInfoItem;
