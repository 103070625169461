import React, { useEffect, useState } from 'react';
import { Scan } from '@/svg';
import Loader from '../loader/Loader';
import Text from '../text/Text';

import { getQRCode } from '@/components/ui/order/actions';
import { CircleX } from 'lucide-react';
import { useTranslation } from '@/lib/i18n';

interface Props {
    id?: string;
    accessToken?: string | null;
    userToken?: string | null;
}

const QRImage: React.FC<{ qrCode?: string; isLoading: boolean }> = (props) => {
    const { qrCode, isLoading } = props;
    const { t } = useTranslation();

    if (isLoading) {
        return (
            <div className="relative mx-auto my-8 h-[12rem] w-[12rem] md:mx-0">
                <Loader className="absolute h-20 w-20" isVisible />
            </div>
        );
    }

    if (qrCode) {
        return (
            <div className="mx-auto my-8 h-[12rem] w-[12rem] md:mx-0">
                <img alt="qrCode" className="h-full w-full" src={qrCode} />
            </div>
        );
    }

    return (
        <div className="text-danger-medium border-danger-medium flex h-full w-full flex-col justify-center gap-2 rounded-lg border bg-white px-4 py-8 text-center md:max-w-[20rem]">
            <CircleX className="text-danger-medium mx-auto h-10 w-10" />
            <Text.H5>{t('qr_code_error_title')}</Text.H5>
            <Text.Body>{t('qr_code_error_description')}</Text.Body>
        </div>
    );
};

export const PaymentQR: React.FC<Props> = (props) => {
    const { id, userToken, accessToken } = props;
    const [qrCode, setQrCode] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchQRCode = async () => {
            if (id) {
                const qrCodeData = await getQRCode(id, userToken, accessToken);
                try {
                    setQrCode(URL.createObjectURL(qrCodeData as unknown as Blob));
                } catch {
                    setQrCode(undefined);
                }

                setIsLoading(false);
            }
        };

        fetchQRCode();
    }, []);

    return (
        <div className="flex flex-row items-center gap-4">
            <QRImage qrCode={qrCode} isLoading={isLoading} />
            <Scan className="hidden md:block" />
        </div>
    );
};

export default PaymentQR;
