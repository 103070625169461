import React from 'react';
import { PromoBanner } from '@/svg';

import Text from '../text/Text';
import { useTranslation } from '@/lib/i18n';
import Button from '../button/Button';

const BecomeDealer: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-primary-500 flex w-full flex-col items-center space-y-8 rounded-2xl p-8 lg:sticky lg:top-32 lg:max-w-[28rem]">
            <PromoBanner />
            <Text.Subtitle className="text-center text-white">{t('become_dealer')}</Text.Subtitle>
            <Button color="light" type="button" href="/join-us">
                {t('join_us')}
            </Button>
        </div>
    );
};

export default BecomeDealer;
