import React from 'react';
import { useTranslation } from '@/lib/i18n';
import { cva } from 'class-variance-authority';
import type { Product } from '@/lib/resources/product';
import { Text, Price, Badge } from '..';
import { useForm } from 'react-hook-form';
import { addToCart, type FormValues } from '@/lib/cart';
import { getHydraId } from '@/lib/hydra';
import AddButton from '@/components/ui/button/AddButton';

const PriceTag = (props: { isBig: boolean; children: React.ReactNode }) => {
    const { isBig, children } = props;
    const Tag = isBig ? Text.H3 : Text.H4;
    return (
        <Tag as="span" className="flex items-baseline gap-1">
            {children}
        </Tag>
    );
};

export interface ProductCardProps extends Product {
    isLoggedIn: boolean;
    isDealer: boolean;
}

const ProductCard = (props: ProductCardProps) => {
    const {
        id,
        name,
        isLoggedIn,
        isDealer,
        taxRate,
        priceForUnregisteredWithTax,
        priceAfterDiscountWithTax,
        priceForRegisteredWithTax,
        points,
    } = props;

    const { t } = useTranslation();
    const isBestSeller = id?.endsWith('3');
    const reducedPrice = cva('', {
        variants: {
            isLoggedIn: {
                true: `${t('product.price_with_your_discount')}`,
                false: t('product.price_after_registration'),
            },
        },
    });

    const formMethods = useForm<FormValues>({
        defaultValues: {
            id: props.id,
            quantity: 1,
        },
    });

    return (
        <div className="flex h-full flex-col space-y-4">
            <a
                className="relative block h-[28.5rem] bg-gray-200"
                href={`/products/oxino-${getHydraId(id)}`}
                title={name}
            >
                <img
                    className="mx-auto h-full max-h-[28.5rem] w-full object-cover"
                    src={`/img/oxino-${getHydraId(id)}-1.webp`}
                    alt={name}
                    loading="lazy"
                />
                {isBestSeller ? (
                    <div className="absolute right-3 top-3 flex h-28 w-28 items-center bg-[url('/img/best-seller.png')] bg-contain bg-no-repeat p-4 text-center align-middle font-bold uppercase leading-none text-white sm:right-5 sm:top-5 sm:h-32 sm:w-32">
                        {t('product.best_seller')}
                    </div>
                ) : null}
            </a>
            <div className="flex flex-grow flex-col">
                <a href={`/products/oxino-${getHydraId(id)}`} title={name}>
                    <div>
                        <div className="flex items-end justify-between">
                            <Text.H4 as="span">{name}</Text.H4>
                            <PriceTag isBig={!isLoggedIn}>
                                <Price {...priceForUnregisteredWithTax} discount={isLoggedIn} />
                                <span className="text-nowrap text-sm font-light">
                                    ({t('with_tax')} {taxRate && `${taxRate}%`})
                                </span>
                            </PriceTag>
                        </div>
                        <Text.Body className="text-primary-500 flex items-baseline justify-between">
                            {reducedPrice({ isLoggedIn })}:{isDealer ? ' *' : ''}
                            <PriceTag isBig={isLoggedIn}>
                                {isLoggedIn ? (
                                    <Price {...priceAfterDiscountWithTax} />
                                ) : (
                                    <Price {...priceForRegisteredWithTax} />
                                )}
                                <span className="text-nowrap text-sm font-light">
                                    ({t('with_tax')} {taxRate && `${taxRate}%`})
                                </span>
                            </PriceTag>
                        </Text.Body>
                    </div>
                    {isDealer && (
                        <Text.Body className="mb-3 text-xs text-gray-600">{t('product.price_info_dealers')}</Text.Body>
                    )}
                    {isDealer && <Badge>{t('product.points_gained', { points: points ?? 0 })}</Badge>}
                    <Text.Body className="mt-2 text-gray-700">{t(`oxino_${getHydraId(id)}.perex`)}</Text.Body>
                </a>
                <form onSubmit={formMethods.handleSubmit(addToCart)} className="mt-auto">
                    <AddButton />
                </form>
            </div>
        </div>
    );
};

export default ProductCard;
