import Lottie, { type LottieRefCurrentProps } from 'lottie-react';
import loadingAnimation from './addToCart.json';
import React, { useEffect } from 'react';
import { useIsClient } from '@/hooks/ssr';

interface Props {
    className?: string;
    isVisible?: boolean;
}

const AddToCart: React.FC<Props> = (props) => {
    const isClient = useIsClient();
    const lottieRef = React.useRef<LottieRefCurrentProps>(null);

    useEffect(() => {
        if (props.isVisible && lottieRef && lottieRef.current) {
            lottieRef.current.goToAndPlay(60);
        }
    }, [props.isVisible]);

    return isClient ? (
        <Lottie
            lottieRef={lottieRef}
            loop={false}
            animationData={loadingAnimation}
            className="pointer-events-none my-auto size-10"
        />
    ) : null;
};

export default AddToCart;
