import { useEffect } from 'react';

import * as CookieConsent from 'vanilla-cookieconsent';
import { getConfig } from '@/components/ui/cookies/config';
import { RESET_PASSWORD_TOKEN_PARAM, TOKEN_PARAM } from '@/constants/params';
import { useTranslation } from '@/lib/i18n.ts';

const Cookies: () => void = () => {
    const { t, locale } = useTranslation();

    const translation = {
        consentModal: {
            description: `🍪 ${t('cookies.consent_modal.description')}`,
            acceptAllBtn: t('cookies.consent_modal.accept_all'),
            acceptNecessaryBtn: t('cookies.consent_modal.accept_necessary'),
            showPreferencesBtn: t('cookies.consent_modal.show_preferences'),
        },
        preferencesModal: {
            title: t('cookies.preferences_modal.cookies_settings'),
            closeIconLabel: t('cookies.preferences_modal.close'),
            acceptAllBtn: t('cookies.consent_modal.accept_all'),
            acceptNecessaryBtn: t('cookies.consent_modal.accept_necessary'),
            savePreferencesBtn: t('cookies.preferences_modal.save_preferences'),
            sections: [
                {
                    title: t('cookies.preferences_modal.sections.necessary.title'),
                    description: t('cookies.preferences_modal.sections.necessary.description'),
                    linkedCategory: 'necessary',
                },
                {
                    title: t('cookies.preferences_modal.sections.functionality.title'),
                    description: t('cookies.preferences_modal.sections.functionality.description'),
                    linkedCategory: 'functionality',
                },
                {
                    title: t('cookies.preferences_modal.sections.analytics.title'),
                    description: t('cookies.preferences_modal.sections.analytics.description'),

                    linkedCategory: 'analytics',
                },
                {
                    title: t('cookies.preferences_modal.sections.marketing.title'),
                    description: t('cookies.preferences_modal.sections.marketing.description'),
                    linkedCategory: 'marketing',
                },
            ],
        },
    };

    useEffect(() => {
        document.documentElement.classList.add('cc--darkmode');
    }, []);

    useEffect(() => {
        if (window?.location && !translation.consentModal.acceptAllBtn.startsWith('cookies.')) {
            const params = new URLSearchParams(window.location.search);
            if (Boolean(params?.get(TOKEN_PARAM) || params?.get(RESET_PASSWORD_TOKEN_PARAM))) {
                return;
            }
            CookieConsent.run(getConfig(translation));
        }
    }, [locale, translation.consentModal.acceptAllBtn]);
};

export default Cookies;
