import React from 'react';
import Text from '../text/Text';

type NavLinkProps = {
    href: string;
    children: React.ReactNode;
};

const NavLink: React.FC<NavLinkProps> = (props) => (
    <a href={props.href} className="inline-flex justify-center px-3 py-6">
        <Text.Body className="font-medium" as="span">
            {props.children}
        </Text.Body>
    </a>
);

export default NavLink;
