import { Text } from '@/components/ui';

import React from 'react';
import PasswordRequirement from './PasswordRequirement';
import { checkValidity, minLength } from './validation';
import { useTranslation } from '@/lib/i18n';

type Props = {
    password: string;
};

const Requirements: React.FC<Props> = ({ password }) => {
    const { t } = useTranslation();
    const validity = checkValidity(password);

    return (
        <div className="flex flex-col gap-2">
            <Text.Body>{t('validation.password.length', { length: `${minLength}` })}:</Text.Body>
            <PasswordRequirement isChecked={validity.lowerCaseLetter}>
                {t('validation.password.one_lowercase_character')}
            </PasswordRequirement>
            <PasswordRequirement isChecked={validity.upperCaseLetter}>
                {t('validation.password.one_uppercase_character')}
            </PasswordRequirement>
            <PasswordRequirement isChecked={validity.oneNumber}>
                {t('validation.password.one_number')}
            </PasswordRequirement>
            <PasswordRequirement isChecked={validity.specialCharacter}>
                {t('validation.password.one_special_character')}
            </PasswordRequirement>
        </div>
    );
};

export default Requirements;
