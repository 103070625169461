import React from 'react';
import { Dialog, DialogContent } from '@/components/ui';

import PasswordChangeForm from './PasswordChangeForm';

type Props = {
    token?: string | null;
};
const ResetPasswordDialog: React.FC<Props> = ({ token }) => {
    return (
        <Dialog defaultOpen={true}>
            <DialogContent
                onInteractOutside={() => {
                    window.location.href = '/';
                }}
            >
                <PasswordChangeForm token={token} resetPassword />
            </DialogContent>
        </Dialog>
    );
};

export default ResetPasswordDialog;
