import React from 'react';
import { useTranslation } from '@/lib/i18n.ts';
import { Button, Dialog, DialogContent, DialogTrigger, Text } from '@/components/ui';
import PasswordChangeForm from '@/components/ui/password-change-form/PasswordChangeForm';
import type { User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read_company_details_read as User } from '@expanzi/api-types';
import { Lock, Trash2 } from 'lucide-react';
import { DeleteAccountDialog } from '@/components/ui/profile/DeleteAccountDialog.tsx';
import { isDealer } from '@/lib/role.ts';

type Props = {
    token?: string | null;
    user: User | null;
};

const ProfileSettings: React.FC<Props> = ({ token, user }) => {
    const { t } = useTranslation();

    return (
        <div className="grid gap-6 lg:grid-cols-2">
            <div className="mt-6 flex flex-col gap-y-8 rounded-xl bg-white p-8">
                <Text.Body>{t('password_change.description')}</Text.Body>
                <Dialog>
                    <DialogTrigger asChild>
                        <Button className="mt-auto md:w-fit">
                            <Text.Body bold>{t('password_change.cta')}</Text.Body>
                            <Lock className="pb-1" />
                        </Button>
                    </DialogTrigger>
                    <DialogContent className="overflow-y-scroll">
                        <PasswordChangeForm token={token} user={user} />
                    </DialogContent>
                </Dialog>
            </div>
            {!isDealer(user) && (
                <div className="mt-6 flex flex-col gap-y-8 rounded-xl bg-white p-8">
                    <Text.Body as="span">{t('delete_profile.description')} </Text.Body>
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button className="mt-auto md:w-fit" variant="outline" color="error">
                                <Text.Body bold>{t('delete_profile.deletion')}</Text.Body>
                                <Trash2 className="pb-1" />
                            </Button>
                        </DialogTrigger>
                        <DialogContent>
                            <DeleteAccountDialog token={token} user={user} />
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </div>
    );
};

export default ProfileSettings;
