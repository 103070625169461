import React from 'react';
import { cn } from '@/lib/utils';

type BadgeAttributes = React.HTMLAttributes<HTMLElement>;

export type BadgeProps = BadgeAttributes;

const Badge: React.FC<BadgeProps> = ({ className, children, ...rest }) => (
    <div
        className={cn(
            [
                'font-geist',
                'text-base',
                'w-fit',
                'bg-primary-500',
                'font-medium',
                'inline-block',
                'rounded-sm',
                'px-2.5',
                'py-1.5',
                'uppercase',
                'leading-[1.24rem]',
                'text-white',
            ],
            className,
        )}
        {...rest}
    >
        {children}
    </div>
);

export default Badge;
