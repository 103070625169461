import React from 'react';
import { useTranslation } from '@/lib/i18n';
import { Checkbox, Text } from '@/components/ui';
import type { DeliveryInfoFormValues } from '@/lib/resources/cart';
import type { CountryCodelist } from '@/lib/resources/codelist';
import type { User_jsonld_user_read_file_read_dealer_read_client_read_storeman_read_company_details_read_address_read as User } from '@expanzi/api-types';

import usePrefillDealerDetails from './usePrefillDealerDetails';
import BillingDetailsPartForm from './BillingDetailsPartForm';
import InvoicePartForm from './InvoicePartForm';
import DeliveryDetailsPartForm from './DeliveryDetailsPartForm';

type DeliveryInformationFormProps = DeliveryInfoFormValues & {
    user?: User | null;
    isDisabled?: boolean;
    allCountries: CountryCodelist[];
    deliveryCountries: CountryCodelist[];
};

const PersonalInformationForm: React.FC<DeliveryInformationFormProps> = (props) => {
    const { user } = props;

    const { t } = useTranslation();
    const { isPrefill, setIsPrefill } = usePrefillDealerDetails(user?.dealer);

    return (
        <div className="flex grow flex-col gap-6">
            <div className="border-b-2">
                <Text.H3 className="mb-2 font-bold">{t('register.finalize')}</Text.H3>
                <Text.Body className="text-gray pb-4 lg:pb-6">{t('register.finalize_description')}</Text.Body>
                {user?.dealer ? (
                    <Checkbox
                        checked={isPrefill}
                        classNameWrapper="ml-1 pb-4"
                        onChange={(e) => setIsPrefill(() => e.target.checked)}
                    >
                        {t('register.load_from_dealer_profile')}
                    </Checkbox>
                ) : null}
            </div>
            <BillingDetailsPartForm {...props} />
            <InvoicePartForm {...props} />
            <DeliveryDetailsPartForm {...props} isSelectable />
        </div>
    );
};

export default PersonalInformationForm;
