import React from 'react';
import { CartSummary, OrderStateCard, Section, Text } from '@/components/ui';
import { email } from '@/lib/contacts';
import { ChevronDown, Email } from '@/svg';
import { useTranslation } from '@/lib/i18n';
import { type BankAccount, getProducts } from '@/lib/resources/invoices';
import { getHydraCurrency, getHydraId } from '@/lib/hydra';
import Address from '@/components/ui/order/Address';
import OrderDetailsPayment from './OrderDetailsPayment';
import type { DeliveryMethod } from '@/lib/resources/delivery';
import type { Invoice_jsonld_invoice_read_product_read_invoice_detail_file_read as Invoice } from '@expanzi/api-types';

interface Props {
    invoice?: Invoice;
    isLoggedIn: boolean;
    isDealer?: boolean;
    token?: string | null;
    accessToken?: string | null;
    account?: BankAccount | null;
    deliveryMethods: DeliveryMethod[];
}
const Order: React.FC<Props> = (props) => {
    const { invoice, account, deliveryMethods, accessToken, token, isLoggedIn, isDealer } = props;

    const { t } = useTranslation();

    const currency = getHydraCurrency(invoice?.currency);

    return (
        <Section className="pt-6 lg:pt-6">
            <div className="flex flex-col gap-8">
                {isLoggedIn && (
                    <a className="flex items-center gap-2" href="/profile">
                        <ChevronDown className="rotate-90" />
                        <Text.Body bold>{t('orders.orders')}</Text.Body>
                    </a>
                )}
                <OrderStateCard state={invoice?.state} orderNumber={getHydraId(invoice?.id) ?? ''} />
                <div className="flex flex-col gap-8 lg:flex-row">
                    <div className="block lg:w-4/6">
                        <OrderDetailsPayment
                            token={token}
                            account={account}
                            state={invoice?.state}
                            accessToken={accessToken}
                            invoicePdfUrl={invoice?.invoicePdf}
                            proformaPdfUrl={invoice?.proformaPdf}
                            id={getHydraId(invoice?.id) ?? ''}
                            createdAt={invoice?.createdAt}
                            totalPrice={{
                                value: Number(invoice?.totalPriceCharged) ?? 0,
                                currency: getHydraCurrency(invoice?.currency),
                            }}
                            variableSymbol={invoice?.variableSymbol}
                        />
                        <div className="mt-4 flex flex-col gap-5 bg-white p-6 shadow lg:flex-row lg:gap-20">
                            <div className="flex-1">
                                <Text.H4>{t('delivery_information_form.billing_details')}</Text.H4>
                                <div className="mt-4">
                                    {invoice?.billingDetails?.name && (
                                        <Text.Body>{invoice?.billingDetails?.name}</Text.Body>
                                    )}
                                    {invoice?.billingDetails?.ico && (
                                        <Text.Body>
                                            {t('delivery_information_form.cin')}: {invoice?.billingDetails?.ico}
                                        </Text.Body>
                                    )}
                                    {invoice?.billingDetails?.dic && (
                                        <Text.Body>
                                            {t('delivery_information_form.vatin')}: {invoice?.billingDetails?.dic}
                                        </Text.Body>
                                    )}
                                    <Text.Body>
                                        {invoice?.billingDetails?.contactPersonFirstName}{' '}
                                        {invoice?.billingDetails?.contactPersonLastName}
                                    </Text.Body>
                                    <Text.Body className="break-words">
                                        {invoice?.billingDetails?.contactPersonEmail}
                                    </Text.Body>
                                    <Text.Body>{invoice?.billingDetails?.contactPersonPhoneNumber}</Text.Body>
                                    <Address {...invoice?.billingDetails?.address} />
                                </div>
                            </div>
                            <div className="flex-1">
                                <Text.H4>{t('order.delivery_information')}</Text.H4>
                                <div className="mt-4">
                                    <Text.Body>
                                        {invoice?.deliveryDetails?.contactPersonFirstName}{' '}
                                        {invoice?.deliveryDetails?.contactPersonLastName}
                                    </Text.Body>
                                    <Text.Body className="break-words">
                                        {invoice?.deliveryDetails?.contactPersonEmail}
                                    </Text.Body>
                                    <Text.Body>{invoice?.deliveryDetails?.contactPersonPhoneNumber}</Text.Body>
                                    <Address {...invoice?.deliveryDetails?.address} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block lg:w-2/6">
                        <div className="flex grow flex-col gap-6 bg-white p-5 pb-16 shadow">
                            <CartSummary
                                deliveryMethods={deliveryMethods}
                                selectedDelivery={invoice?.deliveryMetadata?.provider}
                                cartPrice={{
                                    currency: currency,
                                    value: Number(invoice?.totalProductPriceWithTax ?? '0'),
                                }}
                                deliveryPrice={{
                                    currency: currency,
                                    value: Number(invoice?.deliveryPriceWithTax ?? '0'),
                                }}
                                roundedPrice={{
                                    currency: currency,
                                    value:
                                        Number(invoice?.totalPriceCharged ?? '0') -
                                        Number(invoice?.totalPriceWithTax ?? '0'),
                                }}
                                finalPrice={{
                                    currency: currency,
                                    value: Number(invoice?.totalPriceCharged ?? '0'),
                                }}
                                totalPrice={{
                                    currency: currency,
                                    value: Number(invoice?.totalPriceWithTax ?? '0'),
                                }}
                                taxRate={{
                                    currency,
                                    value:
                                        Number(invoice?.totalPriceWithTax ?? '0') - Number(invoice?.totalPrice ?? '0'),
                                }}
                                deliveryTax={invoice?.deliveryTaxRate}
                                totalPoints={isDealer ? Number(invoice?.totalProductPoints ?? '0') : undefined}
                                discount={Number(invoice?.discountRateApplied ?? '0')}
                                products={getProducts(invoice?.items, currency)}
                            />
                        </div>
                        <div className="mt-4 flex flex-col gap-4 bg-white p-6 shadow">
                            <div>
                                <Text.H4>{t('order_question')}</Text.H4>
                                <Text.Body>{t('contact_us')}</Text.Body>
                            </div>
                            <a className="inline-flex items-center gap-x-3 hover:underline" href={`mailto:${email}`}>
                                <Email width={30} height={30} />
                                <Text.Body bold as="span">
                                    {email}
                                </Text.Body>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default Order;
