import React from 'react';
import { useTranslation } from '@/lib/i18n';

import { Section, ButtonCTA } from '..';
import AuthorizationDialog from '../authorization-dialog/AuthorizationDialog';
import { cn } from '@/lib/utils';

interface Props {
    referral?: string;
    dealerAvatar?: string | null;
}

const RegisterCTA: React.FC<Props> = (props) => {
    const { referral, dealerAvatar } = props;
    const { t } = useTranslation();
    return (
        <Section className="bg-primary-500 sm-px-6 px-0 py-8 md:py-20">
            <div className="flex gap-x-16 gap-y-6 max-xl:flex-col">
                <div className="h-[10.25rem] w-[7.75rem] shrink-0 overflow-hidden rounded-full max-xl:mx-auto xl:h-[17.625rem] xl:w-[13.25rem]">
                    <img
                        alt=""
                        width="319"
                        height="423"
                        loading="lazy"
                        aria-hidden="true"
                        src={dealerAvatar ?? '/img/register-cta-cover.png'}
                        className={cn('object-cover', { ['h-full']: Boolean(dealerAvatar) })}
                    />
                </div>
                <div className="space-y-8 xl:space-y-12">
                    <p className="text-[2rem] font-bold uppercase leading-[2.4rem] text-white max-xl:text-center xl:text-6xl xl:leading-[5rem]">
                        {t('register_cta.register_for_lower_prices')}
                    </p>
                    <AuthorizationDialog dealerId={referral}>
                        <ButtonCTA className="flex max-w-full max-xl:mx-auto">{t('register_cta.join_us')}</ButtonCTA>
                    </AuthorizationDialog>
                </div>
            </div>
        </Section>
    );
};

export default RegisterCTA;
