import client from '@/lib/api';

export async function getQRCode(id: string, userToken?: string | null, invoiceToken?: string | null) {
    if (userToken) {
        const res = await client.api(
            `/api/invoices/${id}/payment/qrcode`,
            {
                method: 'GET',
                token: userToken,
            },
            'image/png',
        );

        if (res.ok) {
            return await res.blob();
        }
    }

    if (invoiceToken) {
        const res = await client.api(
            `/api/public/invoices/${id}/payment/qrcode?token=${invoiceToken}`,
            {
                method: 'GET',
            },
            'image/png',
        );

        if (res.ok) {
            return await res.blob();
        }
    }

    return undefined;
}
