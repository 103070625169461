import type { Invoice_jsonld_invoice_read_product_read_invoice_detail_file_read } from '@expanzi/api-types';
import type { ProductInCart } from '@/lib/resources/product';
import { getHydraCurrency, getHydraItems } from '@/lib/hydra';

export type Invoice = {
    id?: string;
    owner?: string | null;
    items: ProductInCart[];
    taxRateCharged?: string;
    discountRateApplied: number;
    totalProductPrice: number;
    totalPriceCharged: number;
    totalProductPoints: number;
    deliveryPrice: number;
    totalPrice: number;
    billingDetails?: {
        id?: string;
        dic?: string | null;
        ico?: string | null;
        name?: string | null;
        contactPersonEmail?: string | null;
        contactPersonLastName?: string | null;
        contactPersonFirstName?: string | null;
        contactPersonPhoneNumber?: string | null;
        address?: {
            city?: string | null;
            note?: string | null;
            street?: string | null;
            country?: string | null;
            postcode?: string | null;
            houseNumber?: string | null;
        } | null;
        entityType?: string | null;
    } | null;
    deliveryDetails?: {
        name?: string | null;
        contactPersonEmail?: string | null;
        contactPersonLastName?: string | null;
        contactPersonFirstName?: string | null;
        contactPersonPhoneNumber?: string | null;
        ico?: string | null;
        dic?: string | null;
        address?: {
            city?: string | null;
            note?: string | null;
            street?: string | null;
            country?: string | null;
            postcode?: string | null;
            houseNumber?: string | null;
        } | null;
        entityType?: string | null;
    } | null;
    currency?: string;
    state?: 'created' | 'paid' | 'in-transport' | 'delivered' | 'returned' | 'storno';
    totalProductWeight?: number;
    payments?: Array<string>;
    dealer?: string | null;
    createdAt?: string;
    licenseOrder?: boolean;
    variableSymbol?: string | null;
    deliveryProvider?: string | null;
    invoicePdf?: string | null;
    proformaPdf?: string | null;
};

export type BankAccount = {
    bic?: string | null;
    iban?: string | null;
    label?: string;
    currency?: string;
    id?: string | number;
};

export const getProducts = (items: any, currency: string): ProductInCart[] => {
    return (
        items.map((item: any) => {
            return {
                id: item.product['@id'],
                name: item.product.name,
                points: item.product.points,
                quantity: item.quantity,
                price: { value: item.priceWithTax, currency: currency },
                taxRate: item.taxRate,
            } as ProductInCart;
        }) ?? []
    );
};

export const extractItem = (item: Invoice_jsonld_invoice_read_product_read_invoice_detail_file_read): Invoice => {
    return {
        id: item['@id'],
        owner: item.owner,
        items: getProducts(item.items, getHydraCurrency(item.currency)),
        taxRateCharged: item.taxRateCharged,
        discountRateApplied: item.discountRateApplied as unknown as number,
        totalProductPrice: item.totalProductPrice as unknown as number,
        totalPriceCharged: item.totalPriceCharged as unknown as number,
        totalProductPoints: item.totalProductPoints as unknown as number,
        deliveryPrice: item.deliveryPrice as unknown as number,
        totalPrice: item.totalPrice as unknown as number,
        billingDetails: item.billingDetails,
        deliveryDetails: item.deliveryDetails,
        currency: item.currency,
        state: item.state,
        deliveryProvider: item.deliveryMetadata?.provider,
        totalProductWeight: item.totalProductWeight,
        payments: item.payments,
        dealer: item.dealer,
        createdAt: item.createdAt,
        licenseOrder: item.licenseOrder,
        variableSymbol: item.variableSymbol,
        proformaPdf: item.proformaPdf?.downloadUrl,
        invoicePdf: item.invoicePdf?.downloadUrl,
    };
};

export const extractCollection = (response: any): Invoice[] => {
    const items = getHydraItems<Invoice_jsonld_invoice_read_product_read_invoice_detail_file_read>(response);
    return items.map(extractItem);
};
