import React from 'react';
import { useTranslation } from '@/lib/i18n';
import { useForm, Controller } from 'react-hook-form';
import type { Product } from '@/lib/resources/product';
import { Text, PriceCard, QuantityInput } from '@/components/ui';
import { addToCart, type FormValues } from '@/lib/cart';
import { getHydraId } from '@/lib/hydra';
import AddButton from '@/components/ui/button/AddButton';
import Carousel from '@/components/ui/carousel/Carousel';

type ProductDetailCardProps = Omit<Product, 'description'> & {
    maxQuantity?: number;
    isLoggedIn?: boolean;
    isDealer?: boolean;
};

const ProductDetailCard: React.FC<ProductDetailCardProps> = ({
    id,
    name,
    isLoggedIn,
    isDealer,
    taxRate,
    priceBeforeDiscountWithTax,
    priceAfterDiscountWithTax,
    priceForRegisteredWithTax,
    priceForUnregisteredWithTax,
    maxQuantity = Number.MAX_SAFE_INTEGER,
}) => {
    const { t } = useTranslation();

    const formMethods = useForm<FormValues>({
        defaultValues: {
            id,
            quantity: 1,
        },
    });

    const handleAddToCart = (data: FormValues) => {
        addToCart(data);
        formMethods.reset();
    };

    const productId = getHydraId(id);

    const getProductImages = (): string[] => {
        switch (productId) {
            case '1':
                return ['/img/oxino-1-1.webp', '/img/oxino-1-2.webp', '/img/oxino-1-3.webp', '/img/oxino-1-4.webp'];
            case '2':
                return [
                    '/img/oxino-2-1.webp',
                    '/img/oxino-2-gallery-2.png',
                    '/img/oxino-2-gallery-3.png',
                    '/img/oxino-2-gallery-4.png',
                ];
            case '3':
                return [
                    '/img/oxino-3-1.webp',
                    '/img/oxino-3-gallery-2.png',
                    '/img/oxino-3-gallery-3.png',
                    '/img/oxino-3-gallery-4.png',
                ];
        }
        return [];
    };

    return (
        <form className="grid gap-20 xl:grid-cols-7 xl:gap-10" onSubmit={formMethods.handleSubmit(handleAddToCart)}>
            <div className="order-1 col-span-2 xl:order-2 xl:col-span-3">
                <div className="flex flex-col gap-2">
                    <Text.H1 className="text-primary-600">{name}</Text.H1>
                    <Text.H5 className="font-bold">{t(`oxino_${productId}.description`)}</Text.H5>
                    <Text.Body className="text-gray-700">{t(`oxino_${productId}.sub_description`)}</Text.Body>
                </div>
                <div className="my-4 xl:hidden">
                    <Carousel images={getProductImages()} />
                </div>
                <div>
                    <ul className="text-primary-600 mt-6 list-disc space-y-3 px-4">
                        {[0, 1, 2, 3, 4, 5].map((key) => (
                            <li key={t(`oxino_${productId}.key_features.${key}`)}>
                                <Text.Body>{t(`oxino_${productId}.key_features.${key}`)}</Text.Body>
                            </li>
                        ))}
                    </ul>
                    <div className="mt-6 items-center gap-6 xl:mt-12 2xl:flex">
                        <div className="px-0 md:px-2">
                            <PriceCard
                                isLoggedIn={isLoggedIn}
                                isDealer={isDealer}
                                taxRate={taxRate}
                                priceForRegisteredWithTax={priceForRegisteredWithTax}
                                priceAfterDiscountWithTax={priceAfterDiscountWithTax}
                                priceForUnregisteredWithTax={priceForUnregisteredWithTax}
                                priceBeforeDiscountWithTax={priceBeforeDiscountWithTax}
                            />
                        </div>
                        <div className="ml-auto mt-5 min-w-56">
                            <Controller
                                name="quantity"
                                control={formMethods.control}
                                rules={{ required: true, min: 1, max: maxQuantity }}
                                render={({ field }) => <QuantityInput {...field} max={maxQuantity} />}
                            />
                        </div>
                    </div>
                    {isDealer && (
                        <Text.Body className="mx-5 my-3 text-xs text-gray-600">
                            {t('product.price_info_dealers')}
                        </Text.Body>
                    )}
                    <AddButton />
                </div>
            </div>
            <div className="order-2 hidden xl:order-1 xl:col-span-4 xl:mt-2 xl:block">
                <div className="flex h-full w-full items-center justify-center">
                    <Carousel images={getProductImages()} />
                </div>
            </div>
        </form>
    );
};

export default ProductDetailCard;
