import Marquee from 'react-fast-marquee';
import React from 'react';
import { useTranslation } from '@/lib/i18n';
import { Text } from '..';
import { Logo } from '@/svg';

const RunningLine = () => {
    const { t } = useTranslation();

    return (
        <Marquee autoFill>
            {[0, 1, 2].map((key) => (
                <div
                    key={key}
                    className="bg-primary-500 flex items-center gap-x-8 py-5 pl-8 text-white max-md:justify-center"
                >
                    <Logo />
                    <Text.Body className="text-lg font-bold">{t(`running_line.${key}`)}</Text.Body>
                </div>
            ))}
        </Marquee>
    );
};

export default RunningLine;
