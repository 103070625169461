import React from 'react';
import { cn } from '@/lib/utils';

type HeaderColumnProps = {
    children: React.ReactNode;
    className?: string;
};

const HeaderColumn: React.FC<HeaderColumnProps> = (props) => {
    return <div className={cn('flex grow items-center xl:basis-1/4', props.className)}>{props.children}</div>;
};

export default HeaderColumn;
