import React from 'react';
import { request } from '@/lib/api';
import { useTranslation } from '@/lib/i18n';

import Error from '../form/Error';
import { Alert, Button } from '..';

const NewsletterForm = () => {
    const { t } = useTranslation();
    const [status, setStatus] = React.useState<'success' | 'error' | null>(null);
    const handleSubmit: React.FormEventHandler = React.useCallback((event) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const formData = new FormData(form);
        setStatus(null);
        request('/api/public/newsletter/subscribe', {
            method: 'POST',
            body: { email: formData.get('email') },
        })
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    }, []);

    React.useEffect(() => {
        let timeoutId: number | undefined = undefined;
        if (status === 'success') {
            timeoutId = window.setTimeout(() => {
                setStatus(null);
            }, 5000);
        }
        return () => clearTimeout(timeoutId);
    }, [status]);

    if (status === 'success') {
        return (
            <Alert className="uppercase" color="success">
                {t(`newsletter.subscribe.success`)}
            </Alert>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor="newsletter" className="font-geist block text-2xl font-semibold">
                {t('newsletter.subscribe.email_label')}
            </label>
            <div className="relative space-y-1 pb-9 pt-6">
                <input
                    id="newsletter"
                    name="email"
                    placeholder={t('newsletter.subscribe.email_placeholder')}
                    className="w-full rounded-md bg-white bg-opacity-5 p-4 placeholder:text-lg placeholder:font-semibold"
                />
                {status === 'error' ? (
                    <div className="absolute bottom-3 left-0">
                        <Error>{t('newsletter.subscribe.error')}</Error>
                    </div>
                ) : null}
            </div>
            <Button className="max-md:w-full" type="submit" size="sm" color="light">
                {t('newsletter.subscribe.submit')}
            </Button>
        </form>
    );
};

export default NewsletterForm;
