import React from 'react';
import { useTranslation } from '@/lib/i18n';
import type { Product } from '@/lib/resources/product';
import { Text } from '@/components/ui';
import Feature from '@/components/ui/product-description/Feature';
import WaterAnimation from '@/components/ui/product-description/WaterAnimation';
import PackageLeaflet from './PackageLeaflet';
import DownloadDocuments from '@/components/ui/product-description/DownloadDocuments.tsx';

type ProductDescriptionProps = Pick<Product, 'id'>;

const ProductDescription: React.FC<ProductDescriptionProps> = ({ id }) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col items-center gap-12 pt-12 lg:pt-24">
            <div className="flex flex-col gap-12 lg:w-2/3">
                <div className="text-primary-600 flex flex-col gap-8 text-center">
                    <Text.H3>{t(`oxino_${id}.facts.title`)}</Text.H3>
                    <Text.Subtitle>{t(`oxino_${id}.facts.description`)}</Text.Subtitle>
                </div>
                <div className="flex flex-col justify-center gap-8 md:flex-row">
                    <Feature productId={id} featureId={'0'} />
                    <Feature productId={id} featureId={'1'} />
                    <Feature productId={id} featureId={'2'} />
                </div>
            </div>
            <div className="grid items-center gap-12 md:grid-cols-5">
                <div className="order-1 flex justify-center md:order-2 md:col-span-2 md:mt-2 md:h-full md:items-start">
                    <div className="md:sticky md:right-0 md:top-24">
                        <WaterAnimation />
                    </div>
                </div>
                <div className="order-2 mt-5 flex flex-col justify-center text-start md:order-1 md:col-span-3">
                    <Text.H2>{t(`product_description.title`)}</Text.H2>
                    <div className="mt-6 flex flex-col gap-20">
                        {[0, 1, 2, 3].map((key) => (
                            <div key={t(`product_description.features.${key}`)} className="flex flex-col gap-4">
                                <Text.H3>{t(`product_description.features.${key}.title`)}</Text.H3>
                                <Text.Body className="text-gray">
                                    {t(`product_description.features.${key}.description`)}
                                </Text.Body>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <PackageLeaflet productId={id} />
            <DownloadDocuments productId={id as string} />
        </div>
    );
};

export default ProductDescription;
