import React from 'react';
import { Download, PdfDoc } from '@/svg';
import { Text } from '@/components/ui';
import { useTranslation } from '@/lib/i18n';

export type DownloadFileProps = {
    title: string;
    href: string;
};

const DownloadFile: React.FC<DownloadFileProps> = ({ href, title }) => {
    const { t } = useTranslation();

    return (
        <div className="hover:border-primary-500 relative rounded-lg border border-transparent bg-white" role="region">
            <a
                href={href}
                download={title}
                className="after:ring-primary-500 after:focus-visible:ring-primary-500 after:absolute after:inset-0 after:z-[1] after:rounded-lg after:outline-none after:ring-inset after:content-['']"
                role="button"
                aria-label={`${t('download')} ${title}`}
            >
                <div className="flex w-full flex-col items-center justify-center gap-6 p-8">
                    <PdfDoc className="text-primary-500" />
                    <div className="flex items-center gap-3 uppercase">
                        <Text.Body bold>{title}</Text.Body>
                        <Download />
                    </div>
                </div>
            </a>
        </div>
    );
};

export default DownloadFile;
