import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@/lib/i18n';
import Step from './Step';
import { getCartDetailsData, INITIAL_CART_STEP, setCartDetailsData } from '@/lib/cart';
import { useCartDetailStorageEvent } from '@/hooks/cartHook';

const Stepper = () => {
    const cartDetails = getCartDetailsData();
    const [currentStep, setCurrentStep] = useState(INITIAL_CART_STEP);
    const maxStep = cartDetails.maxStep;

    const { t } = useTranslation();
    const steps = [
        { step: 1, text: t('order.basket') },
        { step: 2, text: t('order.delivery_and_payment') },
        { step: 3, text: t('order.delivery_information') },
    ];

    useEffect(() => {
        setCartDetailsData({ currentStep: INITIAL_CART_STEP });
    }, []);

    const handleStorageEvent = useCallback(() => {
        setCurrentStep(getCartDetailsData().currentStep);
    }, []);

    useCartDetailStorageEvent(handleStorageEvent);

    return (
        <div className="flex flex-row justify-center gap-12 md:justify-start">
            {steps.map(({ step, text }) => (
                <button
                    key={step}
                    onClick={() => {
                        if (step <= cartDetails.maxStep) {
                            setCartDetailsData({ currentStep: step });
                        }
                    }}
                    disabled={step > cartDetails.maxStep}
                    className="disabled:cursor-not-allowed"
                >
                    <Step
                        key={step}
                        isFinished={maxStep >= step}
                        isActive={currentStep === step}
                        step={step}
                        text={text}
                    />
                </button>
            ))}
        </div>
    );
};

export default Stepper;
