import React from 'react';

import NavLink from './NavLink';
import { navVariants } from './variants';
import type { NavVariantProps } from './variants';

type NavProps = NavVariantProps;

const Nav: React.FC<NavProps> = ({ vertical }) => (
    <nav className={navVariants({ vertical })}>
        <NavLink href="/products/oxino-1">OXINO №1</NavLink>
        <NavLink href="/products/oxino-2">OXINO №2</NavLink>
        <NavLink href="/products/oxino-3">OXINO №3</NavLink>
    </nav>
);

export default Nav;
