import React from 'react';
import { useTranslation } from '@/lib/i18n';
import type { Product } from '@/lib/resources/product';

import ProductCard from './ProductCard';
import { Text, Section } from '..';

export type ProductsProps = {
    items: Product[];
    isLoggedIn: boolean;
    isDealer: boolean;
};

const Products: React.FC<ProductsProps> = (props) => {
    const { isLoggedIn, isDealer, items } = props;

    const { t } = useTranslation();
    return (
        <Section>
            <Text.H1 className="uppercase">{t('product.our_products')}</Text.H1>
            <div className="grid gap-x-12 gap-y-16 md:grid-cols-2 xl:grid-cols-3">
                {items.map((item) => (
                    <ProductCard {...item} isLoggedIn={isLoggedIn} isDealer={isDealer} key={item.id} />
                ))}
            </div>
        </Section>
    );
};

export default Products;
