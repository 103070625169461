import React from 'react';
import { Text } from '@/components/ui';
import { getHydraId } from '@/lib/hydra';

type AddressProps = {
    note?: string | null;
    city?: string | null;
    street?: string | null;
    country?: string | null;
    postcode?: string | null;
    houseNumber?: string | null;
};
const Address: React.FC<AddressProps> = ({ city = '', street = '', postcode = '', houseNumber = '', country = '' }) => {
    return (
        <Text.Body>
            {street} {houseNumber}, {postcode}, {city} {getHydraId(country)}
        </Text.Body>
    );
};

export default Address;
