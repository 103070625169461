import React from 'react';
import { List, X } from '@/svg';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    open: boolean;
};

const Burger: React.FC<ButtonProps> = ({ open, ...rest }) => (
    <button {...rest} className="inline-flex h-8 w-8 items-center justify-center px-1 py-2 xl:hidden">
        {open ? <X /> : <List />}
    </button>
);

export default Burger;
