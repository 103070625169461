import React from 'react';
import { useTranslation } from '@/lib/i18n.ts';
import { CircleBlur } from '@/svg';

const WaterAnimation = () => {
    const { t } = useTranslation();

    return (
        <div className="relative overflow-hidden">
            <CircleBlur className="absolute -inset-1" />
            <video
                loop
                muted
                autoPlay
                playsInline
                className="size-80 rounded-full object-cover shadow-inner shadow-gray-950"
            >
                <source src="/video/water.mp4" type="video/mp4" />
                {t('videoNotSupported')}
            </video>
        </div>
    );
};

export default WaterAnimation;
