import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { useTranslation } from '@/lib/i18n';
import { Basket } from '@/svg';
import { Button } from '@/components/ui';
import AddToCart from './AddToCart';

const AddButton = () => {
    const { t } = useTranslation();
    const [isLottieVisible, setIsLottieVisible] = useState(false);
    const [isShowing, setIsShowing] = useState(false);

    const handleClick = () => {
        setIsLottieVisible(true);
        setIsShowing(true);
        setTimeout(() => {
            setIsLottieVisible(false);
            setIsShowing(false);
        }, 3000);
    };

    return (
        <Button
            className="hover:bg-primary-500 hover:border-primary-500 active:bg-primary-600 mt-3 block h-20 w-full overflow-hidden hover:text-white"
            onClick={handleClick}
        >
            <Transition
                show={!isShowing}
                enter="transition ease duration-1000 transform"
                enterFrom="opacity-0 -translate-y-10"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease duration-400 transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-10"
            >
                <div className="flex w-full items-center justify-center gap-3 px-5">
                    <Basket />
                    {t('product.add_to_cart')}
                </div>
            </Transition>
            <Transition
                show={isShowing}
                enter="transition ease duration-1000 transform"
                enterFrom="opacity-0 translate-y-10"
                enterTo="opacity-100 -translate-y-0"
                leave="transition ease duration-400 transform"
                leaveFrom="opacity-100 -translate-y-0"
                leaveTo="opacity-0 translate-y-10"
            >
                <div className="flex h-full w-full items-center justify-center">
                    <div className="flex items-center justify-center gap-3">
                        <AddToCart isVisible={isLottieVisible} />
                        {t('product.added')}
                    </div>
                </div>
            </Transition>
        </Button>
    );
};

export default AddButton;
