import React from 'react';
import { Button, Text } from '@/components/ui';
import { useTranslation } from '@/lib/i18n';

const NoOrdersCard = () => {
    const { t } = useTranslation();

    return (
        <div className="rounded-md bg-white p-14">
            <div className="flex flex-col items-center justify-center gap-6 text-center">
                <Text.H4>{t('orders.no_orders')}</Text.H4>
                <Text.Body>{t('orders.start_shopping_today')}</Text.Body>
                <Button size="sm" href={'/'} type="button" className="mt-4 w-full sm:w-72">
                    {t('orders.start_shopping')}
                </Button>
            </div>
        </div>
    );
};

export default NoOrdersCard;
