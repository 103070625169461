import React from 'react';
import { Price, Text } from '@/components/ui';
import { cn } from '@/lib/utils.ts';
import { useTranslation } from '@/lib/i18n.ts';
import type { Product } from '@/lib/resources/product';

const PriceTag = (props: { isBig: boolean; children: React.ReactNode }) => {
    const Tag = props.isBig ? Text.H3 : Text.H4;
    return (
        <Tag as="div" className="flex items-baseline gap-2">
            {props.children}
        </Tag>
    );
};

export type PriceCardProps = Pick<
    Product,
    | 'taxRate'
    | 'priceBeforeDiscountWithTax'
    | 'priceForRegisteredWithTax'
    | 'priceAfterDiscountWithTax'
    | 'priceForUnregisteredWithTax'
> & {
    isLoggedIn?: boolean;
    isDealer?: boolean;
};

const PriceCard: React.FC<PriceCardProps> = ({
    taxRate,
    priceForRegisteredWithTax,
    priceAfterDiscountWithTax,
    priceForUnregisteredWithTax,
    isLoggedIn = false,
    isDealer,
}) => {
    const { t } = useTranslation();

    return (
        <div
            className={cn(['flex', 'flex-col', 'rounded-lg', 'overflow-hidden', 'shadow'], {
                ['flex-col-reverse']: isLoggedIn,
            })}
        >
            <div className="flex items-center justify-center gap-2.5 bg-white px-8 py-2 2xl:flex-col">
                <Text.Body className={cn('mr-auto hidden 2xl:mr-0', { ['block']: isLoggedIn })}>
                    {t('product.current_price')}
                </Text.Body>
                <PriceTag isBig={!isLoggedIn}>
                    <Price {...priceForUnregisteredWithTax} taxRate={taxRate} />
                    <span className="text-nowrap text-base font-light">
                        ({t('with_tax')} {taxRate && `${taxRate}%`})
                    </span>
                </PriceTag>
            </div>
            <div className="bg-primary-500 flex flex-col items-center gap-2.5 px-8 py-2 text-white">
                <Text.Body bold className="text-base">
                    {isLoggedIn
                        ? `${t('product.price_with_your_discount')}${isDealer ? ' *' : ''}`
                        : t('product.price_after_registration')}
                </Text.Body>
                <PriceTag isBig={isLoggedIn}>
                    {isLoggedIn ? <Price {...priceAfterDiscountWithTax} /> : <Price {...priceForRegisteredWithTax} />}
                    <span className="text-nowrap text-base font-light">
                        ({t('with_tax')} {taxRate && `${taxRate}%`})
                    </span>
                </PriceTag>
            </div>
        </div>
    );
};

export default PriceCard;
