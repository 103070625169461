import { COOKIES_PRIVACY } from '@/constants/cookies';
import type { CookieConsentConfig, Translation } from 'vanilla-cookieconsent';

export const getConfig = (translations: Translation): CookieConsentConfig => {
    return {
        cookie: {
            name: COOKIES_PRIVACY,
        },
        onConsent: ({ cookie }) => {
            //     TODO do smth if needed
        },
        onChange: ({ cookie, changedCategories }) => {
            //     TODO do smth if needed
        },
        guiOptions: {
            consentModal: {
                layout: 'box',
                position: 'bottom right',
                equalWeightButtons: false,
                flipButtons: false,
            },
            preferencesModal: {
                layout: 'box',
                position: 'right',
                equalWeightButtons: false,
                flipButtons: false,
            },
        },
        categories: {
            necessary: {
                readOnly: true,
            },
            functionality: {
                enabled: true,
            },
            analytics: {
                enabled: true,
            },
            marketing: {
                enabled: true,
            },
        },
        language: {
            default: 'locale',
            translations: {
                locale: translations,
            },
        },
    };
};
