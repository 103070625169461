import React, { useRef, useCallback, useEffect } from 'react';

type GalleryProps = {
    images: string[];
    currentIndex: number;
    onChange: (index: number) => void;
};

const Gallery: React.FC<GalleryProps> = ({ images, currentIndex, onChange }) => {
    const sliderRef = useRef<HTMLDivElement>(null);
    const scrollTimeout = useRef<NodeJS.Timeout | null>(null);

    const handleScrollGallery = useCallback(() => {
        if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current);
        }

        scrollTimeout.current = setTimeout(() => {
            if (sliderRef.current) {
                const newIndex = Math.round(sliderRef.current.scrollLeft / sliderRef.current.clientWidth);
                if (newIndex !== currentIndex) {
                    onChange(newIndex);
                }
            }
        }, 100);
    }, [currentIndex]);

    useEffect(() => {
        sliderRef.current?.scroll({
            left: sliderRef.current.clientWidth * currentIndex,
        });
    }, [currentIndex]);

    return (
        <div
            className="scrollbar-hide flex h-full snap-x snap-mandatory overflow-x-auto"
            ref={sliderRef}
            onScroll={handleScrollGallery}
        >
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={`Image ${index + 1}`}
                    className="h-full w-full min-w-full snap-center snap-always object-cover"
                    loading="lazy"
                />
            ))}
        </div>
    );
};

export default Gallery;
