import type { User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read_company_details_read as User } from '@expanzi/api-types';

export function isDealer(user: User | null) {
    return Boolean(user?.roles?.includes('ROLE_DEALER') && user?.dealer);
}

export const isClient = (user: User | null): boolean => {
    return user?.roles?.length === 1 && user?.roles?.includes('ROLE_USER');
};

export const isAdmin = (user: User | null): boolean => {
    return Boolean(user?.roles?.includes('ROLE_ADMIN'));
};
