import React from 'react';
import { useTranslation } from '@/lib/i18n.ts';
import { Text } from '@/components/ui';
import { Atherosclerosis, BloodVessel, Erythrocytes } from '@/svg';

type FactProps = {
    productId?: string;
    featureId?: string;
};

const Feature: React.FC<FactProps> = ({ productId, featureId }) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col items-center gap-5">
            <div className="flex size-32 items-center justify-center rounded-full bg-white">
                {featureId === '0' && <BloodVessel />}
                {featureId === '1' && <Erythrocytes />}
                {featureId === '2' && <Atherosclerosis />}
            </div>
            <Text.Body className="text-primary-600 text-center uppercase">
                {t(`oxino_${productId}.facts.features.${featureId}`)}
            </Text.Body>
        </div>
    );
};

export default Feature;
