import React from 'react';
import { request } from '@/lib/api';
import { useTranslation } from '@/lib/i18n';

import { Alert, Text, Button } from '..';

const NewsletterUnsubscribeForm = () => {
    const { t } = useTranslation();
    const [status, setStatus] = React.useState<'success' | 'error' | null>(null);
    const handleSubmit: React.FormEventHandler = React.useCallback((event) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const formData = new FormData(form);
        setStatus(null);
        request('/api/public/newsletter/unsubscribe', {
            method: 'POST',
            body: { email: formData.get('email') },
        })
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    }, []);

    return (
        <div className="mx-6 my-8 sm:mx-auto sm:my-24 sm:w-full sm:max-w-xl">
            <div className="rounded-lg bg-white px-6 pb-8 pt-6 sm:rounded-3xl sm:px-12 sm:pb-16 sm:pt-12">
                <Text.H4 className="text-center">{t('newsletter.unsubscribe.header')}</Text.H4>
                <Text.Body className="mt-4 text-center">{t('newsletter.unsubscribe.are_you_sure')}</Text.Body>
                {status === 'error' ? (
                    <Alert className="mt-6" color="error">
                        {t('newsletter.unsubscribe.error')}
                    </Alert>
                ) : null}
                {status === 'success' ? (
                    <Alert className="mt-6" color="success">
                        {t(`newsletter.unsubscribe.success`)}
                    </Alert>
                ) : (
                    <form method="POST" onSubmit={handleSubmit}>
                        <Button className="mt-12 w-full" size="sm" type="submit">
                            {t('newsletter.unsubscribe.submit')}
                        </Button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default NewsletterUnsubscribeForm;
