import React from 'react';
import { Text } from '@/components/ui';
import { cn } from '@/lib/utils';

import { variants, type ComponentVariantProps } from './variants';
import { Checkmark, FilledCrossInCircle, Package, ReturnArrow } from '@/svg';
import { useTranslation } from '@/lib/i18n';

type BannerProps = {
    title: string;
    description?: string;
    icon?: React.ReactNode;
} & ComponentVariantProps;
const Banner: React.FC<BannerProps> = ({ title, description, icon, color }) => {
    return (
        <div className={cn(variants({ color }))}>
            {icon}
            <div className="text-primary-600 space-y-2">
                <Text.H4>{title}</Text.H4>
                <Text>{description}</Text>
            </div>
        </div>
    );
};

type OrderStateCardProps = {
    state?: 'created' | 'paid' | 'in-transport' | 'delivered' | 'returned' | 'storno';
    orderNumber: string;
};

const OrderStateCard: React.FC<OrderStateCardProps> = ({ state, orderNumber }) => {
    const { t } = useTranslation();

    return (
        <>
            {state === 'created' && (
                <Banner
                    color="green"
                    icon={<Checkmark className="size-9" />}
                    description={t('order_states.created.description')}
                    title={t('order_states.created.title', { orderNumber })}
                />
            )}
            {state === 'paid' && (
                <Banner
                    color="green"
                    icon={<Checkmark className="size-9" />}
                    description={t('order_states.paid.description')}
                    title={t('order_states.paid.title')}
                />
            )}
            {state === 'in-transport' && (
                <Banner
                    color={'blue'}
                    icon={<Package className="size-9" />}
                    description={t('order_states.in_transit.description')}
                    title={t('order_states.in_transit.title', { orderNumber })}
                />
            )}
            {state === 'delivered' && (
                <Banner
                    icon={<Checkmark className="text-success size-9" />}
                    description={t('order_states.delivered.description')}
                    title={t('order_states.delivered.title', { orderNumber })}
                />
            )}
            {state === 'returned' && (
                <Banner
                    icon={<ReturnArrow className="size-9" />}
                    description={t('order_states.returned.description')}
                    title={t('order_states.returned.title', { orderNumber })}
                />
            )}
            {state === 'storno' && (
                <Banner
                    icon={<FilledCrossInCircle className="text-danger-medium size-9" />}
                    title={t('order_states.storno.title', { orderNumber })}
                />
            )}
        </>
    );
};

export default OrderStateCard;
