import React, { useEffect } from 'react';

import { Button, Checkbox, Input, PhoneInput, Select, Text } from '@/components/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type {
    CompanyDetails_jsonld_client_read_companyDetails_read_address_read as Company,
    User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read_company_details_read as User,
} from '@expanzi/api-types';
import { useTranslation } from '@/lib/i18n';
import type { CountryCodelist } from '@/lib/resources/codelist';
import AlertZone from '@/components/ui/profile/AlertZone';
import { getFormError } from '@/lib/form';
import { useMutation } from '@tanstack/react-query';
import { request } from '@/lib/api';
import { queryClient } from '@/lib/store';
import { billingDetailsSchema } from '@/components/ui/profile/validations.ts';
import { getHydraId } from '@/lib/hydra.ts';
import { ENTITY_TYPES } from '@/components/ui/profile/types.ts';

interface Props {
    countries: CountryCodelist[];
    user: User | null;
    token?: string | null;
}

const BillingDetailsForm: React.FC<Props> = (props) => {
    const { countries = [], user, token } = props;

    const { t } = useTranslation();

    const isDealer = Boolean(user?.dealer && user.roles?.includes('ROLE_DEALER'));

    const getDetails = () => {
        if (isDealer) {
            return {
                entityType: user?.dealer?.billingDetails?.entityType || ENTITY_TYPES.PERSON,
                ...user?.dealer?.billingDetails,
            };
        }

        return {
            entityType: user?.client?.billingDetails?.entityType || ENTITY_TYPES.PERSON,
            ...user?.client?.billingDetails,
        };
    };

    const formMethods = useForm<Company>({
        resolver: zodResolver(billingDetailsSchema),
        defaultValues: getDetails(),
        mode: 'onBlur',
    });

    const countryOptions = countries.map((country) => ({ text: country.name ?? '', value: country.id ?? '' }));

    useEffect(() => {}, [formMethods.formState.errors]);
    const getError = getFormError(t, formMethods.formState.errors);

    const { mutate, isPending, isSuccess, isError } = useMutation<unknown, unknown, Company>(
        {
            mutationKey: ['billing', user?.id],
            mutationFn: (body) =>
                request(isDealer ? `/api/users/${user?.id}/dealer` : `/api/users/${user?.id}/client`, {
                    body: {
                        billingDetails: body,
                    },
                    token,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                    },
                }),
        },
        queryClient,
    );

    const entityType = getHydraId(formMethods.watch('entityType'));
    const dic = formMethods.watch('dic');

    const changeEntityType = () => {
        if (entityType === 'company') {
            formMethods.setValue('entityType', ENTITY_TYPES.PERSON);
            return;
        }

        formMethods.setValue('entityType', ENTITY_TYPES.COMPANY);
    };

    return (
        <FormProvider {...formMethods}>
            <form className="mt-6 rounded-xl bg-white p-8" onSubmit={formMethods.handleSubmit((data) => mutate(data))}>
                {!isDealer && (
                    <div className="col-span-2 pb-8">
                        <Checkbox
                            checked={entityType === 'company'}
                            onChange={() => changeEntityType()}
                            value="/api/public/companyEntityType/company"
                        >
                            <Text.Body bold as="span">
                                {t('delivery_information_form.company_purchase')}
                            </Text.Body>
                        </Checkbox>
                    </div>
                )}
                <div className="flex flex-col gap-6 lg:grid lg:grid-cols-6">
                    {entityType === 'company' && (
                        <>
                            <Input
                                required
                                type="text"
                                id="company_name"
                                disabled={isDealer}
                                error={getError('name')}
                                label={t('delivery_information_form.company_name')}
                                placeholder={t('delivery_information_form.company_name')}
                                classNameWrapper="col-span-3"
                                {...formMethods.register('name')}
                            />
                            {isDealer ? (
                                <Input
                                    required
                                    type="text"
                                    id="contactPersonRelation"
                                    error={getError('contactPersonRelation')}
                                    disabled={isDealer}
                                    label={t('delivery_information_form.contactPersonRelation')}
                                    placeholder={t('delivery_information_form.company_name')}
                                    classNameWrapper="col-span-3"
                                    {...formMethods.register('contactPersonRelation')}
                                />
                            ) : (
                                <div className="col-span-3" />
                            )}
                        </>
                    )}
                    {entityType !== 'person' && (
                        <>
                            <Input
                                required
                                id="ico"
                                type="text"
                                disabled={isDealer}
                                error={getError('ico')}
                                placeholder={t('placeholder.cin')}
                                label={t('delivery_information_form.cin')}
                                classNameWrapper="col-span-3"
                                {...formMethods.register('ico')}
                            />
                            {dic || !isDealer ? (
                                <Input
                                    id="vatin"
                                    type="text"
                                    disabled={isDealer}
                                    placeholder={t('placeholder.vatin')}
                                    label={`${t('delivery_information_form.vatin')} (${t('not_mandatory')})`}
                                    classNameWrapper="col-span-3"
                                    {...formMethods.register('dic')}
                                />
                            ) : (
                                <div className="col-span-3" />
                            )}
                        </>
                    )}
                    <Input
                        required
                        id="first_name"
                        disabled={isDealer}
                        label={t('contact_form.first_name')}
                        placeholder={t('contact_form.first_name')}
                        error={getError('contactPersonFirstName')}
                        classNameWrapper="col-span-3"
                        {...formMethods.register('contactPersonFirstName')}
                    />
                    <Input
                        required
                        id="last_name"
                        disabled={isDealer}
                        label={t('contact_form.last_name')}
                        placeholder={t('contact_form.last_name')}
                        error={getError('contactPersonLastName')}
                        classNameWrapper="col-span-3"
                        {...formMethods.register('contactPersonLastName')}
                    />
                    <Input
                        required
                        id="email"
                        type="text"
                        disabled={isDealer}
                        label={t('contact_form.email')}
                        placeholder={t('contact_form.email')}
                        error={getError('contactPersonEmail')}
                        classNameWrapper="col-span-3"
                        {...formMethods.register('contactPersonEmail')}
                    />
                    <PhoneInput
                        required
                        id="phone"
                        disabled={isDealer}
                        label={t('contact_form.phone')}
                        placeholder={t('placeholder.phone')}
                        error={getError('contactPersonPhoneNumber')}
                        classNameWrapper="col-span-3"
                        {...formMethods.register('contactPersonPhoneNumber')}
                    />
                    <Select
                        required
                        id="country"
                        disabled={isDealer}
                        options={countryOptions}
                        label={t('address.country')}
                        defaultValue="/api/public/countries/CZ"
                        error={getError('billingDetails.contactPersonPhoneNumber')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.country')}
                    />
                    <Input
                        required
                        id="street"
                        type="text"
                        disabled={isDealer}
                        label={t('address.street')}
                        placeholder={t('placeholder.street')}
                        error={getError('address.street')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.street')}
                    />
                    <Input
                        required
                        type="text"
                        id="houseNumber"
                        disabled={isDealer}
                        label={t('address.street_number')}
                        placeholder={t('placeholder.street_number')}
                        error={getError('address.houseNumber')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.houseNumber')}
                    />
                    <Input
                        required
                        id="zip"
                        type="text"
                        disabled={isDealer}
                        label={t('address.zip')}
                        placeholder={t('placeholder.zip')}
                        error={getError('address.postcode')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.postcode')}
                    />
                    <Input
                        required
                        id="city"
                        type="text"
                        disabled={isDealer}
                        label={t('address.city')}
                        placeholder={t('placeholder.city')}
                        error={getError('address.city')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.city')}
                    />
                </div>
                {isDealer ? (
                    <Text.Body as="div" className="mt-6">
                        {t('forChange')}
                        <a href={`mailto:office@expanzi.eu`} className="text-primary-500 hover:underline">
                            office@expanzi.eu.
                        </a>
                    </Text.Body>
                ) : (
                    <div className="mt-6 flex flex-1 flex-col items-center gap-4 lg:flex-row">
                        <AlertZone isSuccess={isSuccess} isError={isError} />
                        <Button disabled={isPending} type="submit" className="ml-auto w-full lg:w-fit">
                            {t('save_changes')}
                        </Button>
                    </div>
                )}
            </form>
        </FormProvider>
    );
};

export default BillingDetailsForm;
